<template>
  <div v-if="!isloading">
    <!-- Modal Agendamentos -->
    <b-modal
      id="modal-scrollable-app"
      scrollable
      title="Dados do agendamento"
      ok-only
      size="lg"
      v-if="recording && appointment && appointmentStudio"
    >
      <b-row>
        <b-col md="6">
          <span
            class="text-truncate cursor-pointer"
            @click="appointmentOpen(recording.appointment_id)"
          >
            Agendamento: #<strong>{{ recording.appointment_id }}</strong>
          </span>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <span class="text-truncate">
            Data de Agendamento: <strong>{{ appointment.start_date }}</strong>
          </span>
        </b-col>
      </b-row>
      <b-row v-if="appointment.name">
        <b-col md="6">
          <span class="text-truncate">
            Google Calendar: <strong>{{ appointment.name }}</strong>
          </span>
        </b-col>
      </b-row>
      <b-row v-if="appointment.google_event_url">
        <b-col md="6">
          <span class="text-truncate">
            Link do Google Calendar:
           
                <span
                  class="text-truncate cursor-pointer"
                  @click="openInNewTab(appointment.google_event_url)"
                >
                  <strong>Clique aqui para abrir o link</strong>
                </span>
              
          </span>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <span class="text-truncate">
            Projeto: <strong>{{ appointment.project_name }}</strong>
          </span>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <span class="text-truncate">
            Evento/Curso: <strong>{{ appointment.title }}</strong>
          </span>
        </b-col>
      </b-row>
      <b-row
        v-if="appointmentStudio.studios && appointmentStudio.studios.length > 0"
      >
        <b-col md="6">
          <span class="text-truncate">
            Estúdio Principal:
            <strong>{{
              appointmentStudio.studios.find((f) => f.main).name
            }}</strong>
          </span>
        </b-col>
      </b-row>
      <b-row v-if="appointmentStudio.studios && appointmentStudio.studios.length > 0">
        <b-col md="6">
          <span class="text-truncate">
            Operador:
            <strong v-if="appointmentStudio.studios.find((f) => f.main)">{{
              appointmentStudio.studios.find((f) => f.main).operators[0].name
            }}</strong>
          </span>
        </b-col>
      </b-row>
      <b-row v-if="appointmentLinks && appointmentLinks.length > 0">
        <b-col md="6">
          <span class="text-truncate">
            Link(s) do Youtube:
            <ul>
              <li v-for="(ll, index) in appointmentLinks" :key="index">
                <span
                  class="text-truncate cursor-pointer"
                  @click="openInNewTab(ll.youtube_url)"
                >
                  <strong>{{ ll.youtube_url }}</strong>
                </span>
              </li>
            </ul>
          </span>
        </b-col>
      </b-row>
    </b-modal>
    <!-- Fim Modal Agendamentos -->

    <!-- Modal Ocorrências -->
    <b-modal
      id="modal-scrollable"
      scrollable
      title="Lista de Ocorrências"
      ok-only
      size="lg"
      v-if="occurrences || occurrencesRecording"
    >
      <b-tabs pills>
        <b-tab v-if="occurrences">
          <template #title>
            <span class="d-none d-sm-inline">Agendamento</span>
          </template>
          <b-tabs vertical pills>
            <b-tab
              v-for="(entity, i) in occurrencesTabEntity"
              :key="i"
              :title="entity"
            >
              <b-list-group>
                <b-list-group-item
                  v-for="(occurrence, i) in occurrences.filter(
                    (f) => f.occurrenceCategory.occurrenceEntity.name === entity
                  )"
                  :key="i"
                >
                  <p>{{ occurrence.description }}</p>
                  <b-badge variant="info">
                    {{ occurrence.occurrenceCategory.name }}
                  </b-badge>
                </b-list-group-item>
              </b-list-group>
            </b-tab>
          </b-tabs>
        </b-tab>
        <b-tab v-if="occurrencesRecording">
          <template #title>
            <span class="d-none d-sm-inline">Gravação</span>
          </template>
          <b-tabs vertical pills>
            <b-tab
              v-for="(entity, i) in occurrencesRecordingTabEntity"
              :key="i"
              :title="entity"
            >
              <b-list-group>
                <b-list-group-item
                  v-for="(occurrence, i) in occurrencesRecording.filter(
                    (f) => f.occurrenceCategory.occurrenceEntity.name === entity
                  )"
                  :key="i"
                >
                  <p>{{ occurrence.description }}</p>
                  <b-badge variant="info">
                    {{ occurrence.occurrenceCategory.name }}
                  </b-badge>
                </b-list-group-item>
              </b-list-group>
            </b-tab>
          </b-tabs>
        </b-tab>
      </b-tabs>
    </b-modal>
    <!-- Fim Modal Ocorrências -->

    <!-- Modal Observações -->
    <b-modal
      id="modal-scrollable-obs"
      scrollable
      title="Observações"
      ok-only
      size="lg"
      v-if="recording && recording.description"
    >
      <quill-editor
        style="height: 300px; padding-bottom: 45px"
        :options="editorOption"
        :disabled="true"
        v-model="recording.description"
      >
      </quill-editor>
    </b-modal>
    <!-- Fim Modal Observações -->
    <b-row v-if="recording">
      <b-col md="6">
        <b-row>
          <b-col md="6">
            <span class="text-truncate">
              Código da gravação: <strong>{{ recording.id }}</strong>
            </span>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <span class="text-truncate">
              Status:
              <strong
                :style="{ color: recording.finalized ? 'green' : 'orange' }"
                >{{ recording.finalized ? "Fechada" : "Rascunho" }}</strong
              >
            </span>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <span class="text-truncate">
              Professor:<strong> {{ recording.teacher_name }}</strong>
            </span>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <span class="text-truncate">
              Data de Envio:
              <strong>{{
                recording.created_at
                  ? recording.created_at
                      .split("T")[0]
                      .split("-")
                      .reverse()
                      .join("/") +
                    " às " +
                    recording.created_at.split("T")[1].split(":")[0] +
                    ":" +
                    recording.created_at.split("T")[1].split(":")[1] +
                    ":" +
                    recording.created_at
                      .split("T")[1]
                      .split(":")[2]
                      .split(".")[0]
                  : null
              }}</strong>
            </span>
          </b-col>
        </b-row>
        <b-row v-if="recording.recording_media_parent_id">
          <b-col md="6">
            <span class="text-truncate">
              Clone da gravação:
              <strong>{{ recording.recording_media_parent_id }}</strong>
            </span>
          </b-col>
        </b-row>
        <b-row v-if="recording.creator_user_name">
          <b-col md="6">
            <span class="text-truncate">
              Criado por:
              <strong>{{ recording.creator_user_name }}</strong>
            </span>
          </b-col>
        </b-row>
        <b-row v-if="hasChildrensClone">
          <b-col md="6">
            <span class="text-truncate">
              Gravações Clonadas: 
              <b-badge class="mr-50" v-for="(recording, index) in childrensClone" variant="primary"
                          :key="index">
                         {{ recording }} 
                        </b-badge>
            </span>
          </b-col>
        </b-row>
      </b-col>

      <!-- Buttons -->
      <b-col md="6" class="margin">
        <b-button-group size="sm" class="float-right">
          <b-button
            v-b-modal.modal-scrollable-app
            :disabled="!appointmentButton"
            :variant="appointmentButton ? 'info' : 'outline-info'"
          >
            Agendamento
          </b-button>
          <b-button
            v-b-modal.modal-scrollable
            :disabled="!occurrenceButton"
            :variant="occurrenceButton ? 'info' : 'outline-info'"
          >
            Ocorrências
          </b-button>
          <b-button
            v-b-modal.modal-scrollable-obs
            :disabled="!descriptionButton"
            :variant="descriptionButton ? 'info' : 'outline-info'"
          >
            Observações
          </b-button>
        </b-button-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import _appointmentService from "@/services/appointment-service";
import _recordingMediaService from "@/services/audiovisual/recording-media";
import _jobOccurrenceService from "@/services/audiovisual/job-occurrence-service";
import {
  BListGroup,
  BListGroupItem,
  BBadge,
  BButtonGroup,
} from "bootstrap-vue";

export default {
  components: {
    BListGroup,
    BListGroupItem,
    BBadge,
    BButtonGroup,
  },
  data() {
    return {
      isloading: false,
      recordingID: this.$route.params.id,      
      recording: null,
      appointment: null,
      appointmentStudio: null,
      appointmentLinks: null,
      occurrences: null,
      occurrencesTabEntity: null,
      occurrencesRecording: null,
      occurrencesRecordingTabEntity: null,
      editorOption: {
        modules: {
          toolbar: [
            [{ size: ["small", false, "large", "huge"] }],
            ["bold", "italic", "underline"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ color: [] }],
          ],
        },
        theme: "bubble",
      },
      childrensClone: null,
    };
  },
  computed: {
    hasChildrensClone() {
      return this.childrensClone && this.childrensClone.length > 0;
    },
    occurrenceButton() {
      return (this.occurrences && this.occurrences.length > 0) ||
        (this.occurrencesRecording && this.occurrencesRecording.length > 0)
        ? true
        : false;
    },
    appointmentButton() {
      return this.appointment ? true : false;
    },
    descriptionButton() {
      return this.recording && this.recording.description ? true : false;
    },
  },
  mounted() {
    this.getRecord();
    this.getClones();
  },
  methods: {
    async getRecord() {
      this.isloading = true;
      try {
        this.recording = (
          await _recordingMediaService.getDetails(this.recordingID)
        ).content;
        this.occurrencesRecording = (
          await _jobOccurrenceService.findByRecording(this.recordingID)
        ).content;
        if (
          this.recording.appointment_id &&
          this.recording.appointment_id > 0
        ) {
          this.appointment = (
            await _appointmentService.find_short(this.recording.appointment_id)
          ).content;
          this.occurrences = (
            await _jobOccurrenceService.findByAppointment(
              this.recording.appointment_id
            )
          ).content;
          this.appointmentStudio = (
            await _appointmentService.find_short_studios(
              this.recording.appointment_id
            )
          ).content;
          this.appointmentLinks = (
            await _appointmentService.find_short_links(
              this.recording.appointment_id
            )
          ).content;
          this.mountOccurrencesTabEntity();
        }
        this.mountOccurrencesRecordingTabEntity();
      } catch (error) {
        this.$utils.toastError("Notificação", error.toString());
      }
      this.isloading = false;
      this.$emit("mounted");
    },
    async getClones() {
      try {
        this.childrensClone = (
          await _recordingMediaService.getRecordingsCloneByRecording(this.recordingID)
        ).content;
      } catch (error) {
        this.$utils.toastError("Notificação", error.toString());
      }
    },
    mountOccurrencesTabEntity() {
      if (this.occurrences && this.occurrences.length > 0) {
        this.occurrencesTabEntity = this.occurrences
          .map((m) => m.occurrenceCategory.occurrenceEntity.name)
          .filter((v, i, a) => a.indexOf(v) === i);
      }
    },
    mountOccurrencesRecordingTabEntity() {
      if (this.occurrencesRecording && this.occurrencesRecording.length > 0) {
        this.occurrencesRecordingTabEntity = this.occurrencesRecording
          .map((m) => m.occurrenceCategory.occurrenceEntity.name)
          .filter((v, i, a) => a.indexOf(v) === i);
      }
    },
    appointmentOpen(id) {
      if (this.$utils.isPermited("permission.appointment.edit")) {
        this.$router.push({
          path: `/pedagogic/calendar?q=${id}`,
        });
      }
    },
    openInNewTab(url) {
      window.open(url, "_blank", "noreferrer");
    },
  },
};
</script>

