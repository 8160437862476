<template>
  <div v-if="!isloading">
    <!-- Note List -->
    <b-modal ref="modal-obs-list" title="Minutagem" hide-footer scrollable>
      <b-row style="min-height: 200px; margin-top: 10px">
        <b-col cols="12">
          <b-list-group v-for="(element, id) in currentObs" :key="id">
            <b-list-group-item class="p-50 mb-1">
              <p class="ml-25">{{ element.obs }}</p>
              <hr class="mb-50" />
              <div class="d-flex justify-content-between align-items-end">
                <b-badge variant="info" style="min-width: 70px">
                  {{ element.obs_time + " - " + element.obs_end_time }}
                </b-badge>
              </div>
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
      <b-row style="margin-bottom: 8px; margin-top: 20px">
        <b-col style="text-align: right">
          <b-button @click="onClickModalObsList(false)">Fechar</b-button>
        </b-col>
      </b-row>
    </b-modal>
    <!-- End Note List -->

    <!-- INICIO MODAL VÍDEOS ARCHIVE -->
    <b-modal
      size="lg"
      ref="modal-videos-list"
      title="Vídeos"
      hide-footer
      scrollable
    >
      <b-row
        style="min-height: 200px; margin-top: 10px"
        v-for="(video, id) in currentVideos.list"
        :key="id"
      >
        <b-col cols="12">
          <div class="d-flex align-items-center justify-content-center">
            <video
              :src="video.url_media"
              :id="`video-${video.id}`"
              width="100%"
              :poster="
                currentVideos.type == 1
                  ? require('@/assets/images/svg/audio_play.svg')
                  : require('@/assets/images/svg/video_play.svg')
              "
              controls
            />
          </div>
        </b-col>
        <b-col cols="12">
          <div class="d-flex align-items-center justify-content-center">
            <span style="text-align: center"
              ><strong>{{ video.original_filename }}</strong></span
            >
          </div>
        </b-col>
        <b-col cols="12">
          <hr />
        </b-col>
      </b-row>
      <b-row style="margin-bottom: 8px; margin-top: 20px">
        <b-col style="text-align: right">
          <b-button @click="onClickModalVideoList(false)">Fechar</b-button>
        </b-col>
      </b-row>
    </b-modal>
    <!-- FIM MODAL VÍDEOS ARCHIVE -->

    <b-row
      class="mt-2"
      v-for="(mediaFile, index) of mediafiles.filter(
        (f) => f.recordingMediaFiles && f.recordingMediaFiles.length > 0
      )"
      :key="index"
    >
      <b-col cols="12" v-if="index > 0">
        <hr class="p-0 mb-2" />
      </b-col>
      <span id="boot-icon" class="bi bi-play" style="font-size: 10rem"></span>

      <b-col sm="12" md="6" lg="5">
        <video
          :src="null"
          :id="`video-${mediaFile.id}`"
          preload="none"
          width="100%"
          :poster="
            mediaFile.type_media == 1
              ? require('@/assets/images/svg/audio_play.svg')
              : require('@/assets/images/svg/video_play.svg')
          "
          controls
          @loadedmetadata="getDuration(mediaFile)"
          @click="playOrPause(mediaFile)"
        />
      </b-col>
      <b-col>
        <div v-if="isClone">
          <b-form-checkbox
            v-model="mediaFile.mediaSelected"
            switch
            class="mt-50 custom-control-success"
            >Selecionar esse material
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
          </b-form-checkbox>
        </div>
        <div>
          ID: <strong>{{ mediaFile.id }}</strong>
        </div>
        <div>
          Nome do arquivo: <strong>{{ mediaFile.s3_key }}</strong>
        </div>
        <div>
          Tipo:
          <b-badge
            :variant="mediaFile.type_media == 1 ? 'success' : 'info'"
            class="mr-2"
          >
            {{ descType(mediaFile.type_media) }}
          </b-badge>
        </div>
        <div>
          Nome original: <strong>{{ mediaFile.original_filename }}</strong>
        </div>
        <div>
          Duração total:
          <strong :id="`duration-${mediaFile.id}`">
            {{ mediaFile.media_time || "--" }}
          </strong>
        </div>
        <div class="mt-50" v-if="mediaFile.presentation_file_s3_key">
          <a :id="`pdf-${mediaFile.id}`" @click="getPdfUrl(mediaFile)">
            <feather-icon icon="FileTextIcon" size="20" />
            PDF
          </a>
        </div>

        <!-- Assuntos -->
        <b-col
          cols="12"
          class="mt-2"
          v-if="mediaFile.recordingMediaFiles[0]"
          style="max-height: 400px; overflow: auto"
        >
          <div v-if="isClone && mediaFile.mediaSelected">
            <b-form-checkbox
              switch
              class="mt-50 custom-control-success"
              v-model="mediaFile.recordingMediaFiles[0].mediaSubjectAllSelected"
              @change="(value) => checkAllSubjectItems(value, mediaFile.id)"
            >
              Selecionar todos os assuntos
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
            </b-form-checkbox>
          </div>

          <b-col>
            <app-collapse
              v-if="mediaFile.recordingMediaFiles[0]"
              type="border"
              accordion
            >
              <template
                v-for="(mediaSubject, index) in mediaFile.recordingMediaFiles[0]
                  .recordingMediaSubjectRS"
              >
                <b-row :key="index">
                  <b-col
                    v-if="isClone && mediaFile.mediaSelected"
                    cols="1"
                    class="checkbox-subject mr-2"
                  >
                    <b-form-checkbox
                      switch
                      class="custom-control-success"
                      @change="(value) => checkSubject(value, mediaFile.id)"
                      v-model="mediaSubject.subjectSelected"
                    >
                      <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                      </span>
                    </b-form-checkbox>
                  </b-col>
                  <b-col :cols="(isClone && mediaFile.mediaSelected ? '11': '12')">
                    <app-collapse-item
                      :id="mediaSubject.id"
                      :isCustom="true"
                      :title="`ID: ${
                        mediaSubject.id
                      } - ${check_question_informed(mediaSubject.subject)}`"
                      :customSubTitle="mediaSubject.product.name"
                      :isVisible="
                        mediaFile.recordingMediaFiles[0].recordingMediaSubjectRS
                          .length < 2 || visibleSubject(mediaSubject.id)
                      "
                    >
                      <ul class="auto-suggest-suggestions-list">
                        <li
                          style="
                            cursor: pointer;
                            text-decoration: underline;
                            max-width: 150px;
                          "
                          @click="
                            playVideo(mediaFile.id, mediaSubject.start_duration)
                          "
                        >
                          {{ mediaSubject.duration }}
                        </li>
                      </ul>
                      <b-badge
                        v-if="
                          mediaSubject.presentation_file_s3_status_code == 202
                        "
                        variant="warning"
                        class="my-1"
                      >
                        Cortes do PDF em processamento
                      </b-badge>

                      <b-alert
                        fade
                        variant="danger"
                        :show="
                          mediaSubject.presentation_file_s3_status_code == 500
                        "
                      >
                        <h4 class="alert-heading">
                          Atenção! Não possível processar os cortes do PDF.<br />
                          {{ mediaSubject.presentation_file_s3_status_message }}
                        </h4>
                      </b-alert>

                      <div
                        class="mt-1 mb-1"
                        v-if="
                          mediaSubject.presentation_file_s3_key &&
                          mediaSubject.page_start &&
                          mediaSubject.page_end
                        "
                      >
                        <!-- adicionar aqui link para PDF cortado -->
                        <a
                          :href="mediaSubject.presentation_file_s3_url"
                          target="_blank"
                        >
                          <feather-icon icon="FileTextIcon" size="20" /> PDF
                          Páginas {{ mediaSubject.page_start }} até
                          {{ mediaSubject.page_end }}
                        </a>
                      </div>
                      <div>
                        <span class="card-text">
                          Disciplina:
                          <strong>
                            {{ getDiscipline(mediaSubject) }}
                          </strong>
                        </span>
                      </div>
                      <div>
                        <span class="card-text">
                          Classificação:
                          <strong>
                            {{ getClassificationName(mediaSubject) }}
                          </strong>
                        </span>
                      </div>
                      <div>
                        <span class="card-text">
                          Empresa:
                          <strong>
                            {{ mediaSubject.project.company.name || "--" }}
                          </strong>
                        </span>
                      </div>
                      <div>
                        <span class="card-text">
                          Projeto:
                          <strong>
                            {{ mediaSubject.project.name || "--" }}
                          </strong>
                        </span>
                      </div>
                      <div>
                        <span class="card-text">
                          Professor:
                          <strong>
                            {{ mediaSubject.teacher.name || "--" }}
                          </strong>
                        </span>
                      </div>
                      <div
                        v-if="
                          mediaSubject.product_id == 2 &&
                          mediaSubject.institutionsIssues
                        "
                      >
                        <span class="card-text">
                          Instituição:
                          <strong>{{
                            mediaSubject.institutionsIssues.name || "--"
                          }}</strong>
                        </span>
                      </div>
                      <div
                        v-if="
                          mediaSubject.product_id == 2 &&
                          mediaSubject.institutionsIssues
                        "
                      >
                        <span class="card-text">
                          Ano da Questão:
                          <strong>{{
                            mediaSubject.question_year || "--"
                          }}</strong>
                        </span>
                      </div>
                      <div
                        v-if="
                          mediaSubject.media &&
                          mediaSubject.media.platform_excluded_at
                        "
                      >
                        <span
                          >Excluído
                          <span v-if="mediaSubject.media.question_id"
                            ><strong
                              >({{ mediaSubject.media.question_id }})
                            </strong></span
                          >na Plataforma em:
                          <strong>{{
                            new Date(
                              mediaSubject.media.platform_excluded_at
                            ).toLocaleDateString("pt-BR")
                          }}</strong></span
                        >
                      </div>
                      <div class="d-inline-block mt-1">
                        <b-badge
                          v-if="
                            mediaSubject.media_archive &&
                            mediaSubject.media_archive.length > 0
                          "
                          :variant="
                            mediaSubject.media &&
                            mediaSubject.media.platform_excluded_at
                              ? 'danger'
                              : 'info'
                          "
                          @click="
                            onClickModalVideoList(
                              true,
                              mediaSubject.media_archive,
                              mediaFile.type_media
                            )
                          "
                          class="cursor-pointer mr-50"
                        >
                          <feather-icon icon="ArchiveIcon" class="mr-25" />
                          <span>{{ textAssociatesFiles(mediaFile) }}</span>
                        </b-badge>
                        <b-badge
                          v-if="
                            obsList.length > 0 &&
                            obsList.some((s) => s.subject_id == mediaSubject.id)
                          "
                          variant="info"
                          @click="
                            onClickModalObsList(
                              true,
                              obsList.find(
                                (f) => f.subject_id == mediaSubject.id
                              )
                            )
                          "
                          class="cursor-pointer"
                        >
                          <feather-icon icon="ClockIcon" class="mr-25" />
                          <span>Minutagem</span>
                        </b-badge>
                      </div>
                    </app-collapse-item>
                  </b-col>
                </b-row>
              </template>
            </app-collapse>
          </b-col>
        </b-col>
      </b-col>
    </b-row>
    <div class="mt-2">
      <b-button
        v-if="isClone"
        @click="submitClone"
        variant="info"
        style="min-width: 125px; float: right"
        :disabled="isloading"
      >
        <div v-if="!isloading">
          <span class="align-middle">Clonar Gravação</span>
          <feather-icon icon="CheckIcon" class="ml-50" />
        </div>
        <b-spinner v-else small />
      </b-button>
    </div>
  </div>
</template>

<script>
import _recordingMediaFilesService from "@/services/audiovisual/recording-media-files";
import _mediaSubjectVideoService from "@/services/audiovisual/media-subject-video-service";
import _mediaSubjectAudioService from "@/services/audiovisual/media-subject-audio-service";
import _minutagemService from "@/services/audiovisual/minutagem-service";
import {
  BListGroup,
  BListGroupItem,
  BBadge,
  BFormCheckbox,
  BAlert,
} from "bootstrap-vue";

import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import { sub } from "date-fns";

export default {
  props: {
    isClone: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isloading: false,
      recordingID: this.$route.params.id,
      mediafiles: [],
      currentObs: [],
      currentVideos: {
        type: 0,
        list: [],
      },
      obsList: [],
      checkedAll: [],
    };
  },
  components: {
    BListGroup,
    BListGroupItem,
    BBadge,
    AppCollapse,
    AppCollapseItem,
    BFormCheckbox,
    BAlert,
  },
  mounted() {
    this.getRecord();
  },
  methods: {
    async getRecord() {
      this.isloading = true;
      try {
        var lstmediafiles = (
          await _recordingMediaFilesService.findAllByRecording(this.recordingID)
        ).content;

        if (lstmediafiles && lstmediafiles.length > 0) {
          for (const mediafile of lstmediafiles) {
            if (
              mediafile.recordingMediaFiles &&
              mediafile.recordingMediaFiles.length > 0
            ) {
              mediafile.recordingMediaFiles =
                mediafile.recordingMediaFiles.filter(
                  (f) => !f.excluded && f.recording_media_id == this.recordingID
                );
              for (const recMediafile of mediafile.recordingMediaFiles) {
                mediafile.media_file_order = recMediafile.media_file_order;
                if (mediafile.type_media == 0) {
                  //Video
                  recMediafile.recordingMediaSubjectRS = (
                    await _mediaSubjectVideoService.showByRecordingMediaFile(
                      recMediafile.id
                    )
                  ).content;
                } else if (mediafile.type_media == 1) {
                  //Audio
                  recMediafile.recordingMediaSubjectRS = (
                    await _mediaSubjectAudioService.showByRecordingMediaFile(
                      recMediafile.id
                    )
                  ).content;
                }
                if (
                  recMediafile.recordingMediaSubjectRS &&
                  recMediafile.recordingMediaSubjectRS.length > 0
                ) {
                  for (const subject of recMediafile.recordingMediaSubjectRS) {
                    _minutagemService
                      .showByMediaSubject(subject.id)
                      .then((res) => {
                        if (res.content && res.content.length > 0) {
                          this.obsList.push({
                            mediafile_id: mediafile.id,
                            subject_id: subject.id,
                            obs: res.content,
                          });
                        }
                      })
                      .catch((error) => {
                        this.$utils.toastError("Notificação", error.toString());
                      });
                  }
                }
              }
            }
          }
        }
      } catch (error) {
        this.$utils.toastError("Notificação", error.toString());
      }
      lstmediafiles = this.orderList(lstmediafiles);

      lstmediafiles.forEach((element) => {
        if (element?.recordingMediaFiles?.length > 0) {
          element.mediaSelected = true;
          element.recordingMediaFiles[0].mediaSubjectAllSelected = true;
          if (element.recordingMediaFiles[0].recordingMediaSubjectRS.length > 0) {
            element.recordingMediaFiles[0].recordingMediaSubjectRS.forEach(
              (subject) => {
                subject.subjectSelected = true;
              }
            );
          }
        }
      });

      this.mediafiles = lstmediafiles;

      this.isloading = false;

      setTimeout(() => {
        if (this.$route.params.subject) {
          const el = document.getElementById(this.$route.params.subject);
          el.scrollIntoView({ behavior: "smooth" });
        }
      }, 100);
      this.$emit("mounted");
    },
    checkAllSubjectItems(value, mediaFileId) {
      this.mediafiles.forEach((mediaFile) => {
        if (mediaFile.id === mediaFileId) {
          mediaFile.recordingMediaFiles[0].recordingMediaSubjectRS.forEach(
            (subject) => {
              subject.subjectSelected = value;
            }
          );
        }
      });
    },
    checkSubject(value, mediaFileId) {
      this.mediafiles.forEach((mediaFile) => {
        if (mediaFile.id === mediaFileId) {
          if (!value) {
            mediaFile.recordingMediaFiles[0].mediaSubjectAllSelected = false;
            return;
          } else {
            let allSelected =
              mediaFile.recordingMediaFiles[0].recordingMediaSubjectRS.every(
                (subject) => subject.subjectSelected
              );
            mediaFile.recordingMediaFiles[0].mediaSubjectAllSelected =
              allSelected;
          }
        }
      });
    },
    orderList(lst) {
      lst.forEach((element) => {
        if (!element.media_file_order) element.media_file_order = 0;
      });

      lst.sort((a, b) => {
        if (a.media_file_order < b.media_file_order) return -1;
        if (a.media_file_order > b.media_file_order) return 1;
        return 0;
      });

      return lst;
    },
    onClickModalObsList(action, _payload) {
      if (action) {
        this.currentObs = _payload.obs;
        this.$refs["modal-obs-list"].show();
      } else {
        this.$refs["modal-obs-list"].hide();
      }
    },
    playVideo(id_video, start_duration) {
      let meuVideo = document.getElementById(`video-${id_video}`);
      if (meuVideo) {
        meuVideo.currentTime = this.getDurantionInSec(start_duration);
        meuVideo.play();
      }
    },
    getDurantionInSec(duration) {
      let splitedArray = duration.split(":");
      let hours = parseInt(splitedArray[0] * 3600);
      let minutes = parseInt(splitedArray[1] * 60);
      let seconds = parseInt(splitedArray[2]);
      return hours + minutes + seconds;
    },
    getDuration(mediaFile) {
      if (mediaFile.media_time) return;
      let meuVideo = document.getElementById(`video-${mediaFile.id}`);
      let duration = document.getElementById(`duration-${mediaFile.id}`);
      if (meuVideo && duration) {
        let hours = Math.floor(meuVideo.duration / 3600);
        let minutes = Math.floor((meuVideo.duration - hours * 3600) / 60);
        let seconds = Math.floor(meuVideo.duration % 60);
        if (hours < 10) hours = "0" + hours;
        if (minutes < 10) minutes = "0" + minutes;
        if (seconds < 10) seconds = "0" + seconds;
        duration.innerHTML = `${hours}:${minutes}:${seconds}`;
      }
    },
    check_question_informed(name) {
      return name == "questao_nao_identificada"
        ? "Questão não informada"
        : name;
    },
    visibleSubject(subject_id) {
      return this.$route.params.subject &&
        this.$route.params.subject == subject_id
        ? true
        : false;
    },
    onClickModalVideoList(action, _payload, _type_media) {
      if (action) {
        this.currentVideos.list = _payload;
        this.currentVideos.type = _type_media;
        this.$refs["modal-videos-list"].show();
      } else {
        this.$refs["modal-videos-list"].hide();
      }
    },
    descType(media_type) {
      switch (media_type) {
        case 0:
          return "Vídeo";
        case 1:
          return "Audio";
      }
    },
    async playOrPause(media) {
      let video = document.getElementById(`video-${media.id}`);
      if (video) {
        if (media.type_media == 0 && video.readyState == 0) {
          video.src = (
            await _recordingMediaFilesService.getUrlFile(media.s3_key, 0)
          ).content;
          video.play();
        } else if (media.type_media == 1) {
          if (video.readyState == 0) {
            video.src = (
              await _recordingMediaFilesService.getUrlFile(media.s3_key, 0)
            ).content;
          }
          video.paused ? video.play() : video.pause();
        }
      }
    },
    async getPdfUrl(media) {
      let element = document.getElementById(`pdf-${media.id}`);
      if (element.getAttribute("target")) return;

      let s3Key = await _recordingMediaFilesService.getUrlFile(
        media.presentation_file_s3_key,
        1
      );

      if (s3Key.content) {
        element.setAttribute("href", s3Key.content.toString());
        element.setAttribute("target", "_blank");
        window.open(s3Key.content.toString(), "_blank");
      } else {
        this.$utils.toastError(
          "Error",
          "Não foi possível gerar o link para o arquivo"
        );
      }
    },
    getDiscipline(mediaSubject) {
      if (
        mediaSubject &&
        mediaSubject.discipline &&
        mediaSubject.discipline.name
      )
        return mediaSubject.discipline.name;
      else if (
        mediaSubject &&
        mediaSubject.classification &&
        mediaSubject.classification.name
      )
        return mediaSubject.classification.name;
      else return "--";
    },
    getClassificationName(mediaSubject) {
      if (mediaSubject && mediaSubject.classification_platform_name)
        return mediaSubject.classification_platform_name;
      else return "--";
    },
    checkAllItems(items, index) {
      items.forEach((element) => {
        element.selected = !this.checkedAll[index];
      });
    },
    submitClone() {
      const payload = {
        recording_media_id: this.recordingID,
        mediafiles: this.mediafiles
          .filter((f) => f.mediaSelected)
          .map((f) => ({
            id: f.id,
            childrenIds: f.recordingMediaFiles[0].recordingMediaSubjectRS
              .filter((s) => s.subjectSelected)
              .map((s) => s.id),
          })),
      };

      this.$emit("clone", payload);
    },
    textAssociatesFiles(media_file) {
      const withClone = this.isClone ? " - Não serão clonados" : "";
      return media_file.type_media
        ? `Áudios Associados${withClone}`
        : `Vídeos Associados${withClone}`;
    },
  },
};
</script>
<style scoped>
.checkbox-subject {
  max-width: 16px;
  padding-top: 15px;
}
</style>