import HttpService from '../HttpService'

const _http = new HttpService()
export default {
  show: (page, options = null) => {
    let _parans = `?page=${page - 1}`;
    if (options) {
      if(options.search) {
        _parans += `&search=${options.search}`;
      }
      if (options.orderByKey) {
        _parans += `&order_by=${options.orderByKey}`
      }
      if (options.ascOrDes) {
        _parans += '&asc'
      }
    }
    return _http.get(`/api/audio-visual/job-occurrence${_parans}`)
  },
  find: (id) => {
    return _http.get(`/api/audio-visual/job-occurrence/${id}`)
  },
  create: (payload) => {
    return _http.post('/api/audio-visual/job-occurrence', payload)
  },
  update: (payload) => {
    return _http.put('/api/audio-visual/job-occurrence', payload)
  },
  delete: (id) => {
    return _http.delete(`/api/audio-visual/job-occurrence/${id}`)
  },
  findByAppointment: (appointiment_id,is_centraltech) => {

    let _parans = `?relation_key=${appointiment_id}`;
    if (is_centraltech)
      _parans += `&is_centraltech=${is_centraltech}`;

    return _http.get(`/api/audio-visual/job-occurrence/job-occurrence-appointment${_parans}`)
  },
  findByRecording: (recording_media_id,is_centraltech) => {

    let _parans = `?relation_key=${recording_media_id}`;
    if (is_centraltech)
      _parans += `&is_centraltech=${is_centraltech}`;

    return _http.get(`/api/audio-visual/job-occurrence/job-occurrence-recording${_parans}`)
  },
  severalCreate: (payload) => {
    return _http.post('/api/audio-visual/job-occurrence/store-list', payload)
  }
}